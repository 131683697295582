const Swal = require('sweetalert2');

$(function () {
  $(document).on('click', '.btn-detail-special-stage, .btn-detail-special-stage-pickup', function (e) {

    if ($(this).hasClass('is-modal')) {
      e.preventDefault();
      const url = $(this).attr('href');

      Swal.fire({
        template: '#modal-special-stage',
        didRender(popup) {
          if (url) {
            $('#inlineFrame').attr('src', url)
          } else{
            $('#inlineFrame').css({'visibility': 'hidden;opacity:0;'})
          }
        },
        willClose(popup) {
        }
      });
      return false;
    } else {
      return true;
    }
  })
})